import React, { useState, useEffect } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { auth } from "../firebase";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import InputBase from "../components/InputBase";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { CircularProgress } from "@mui/material";

const ResetPassword = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [oobCode, setoobCode] = useState("");

  useEffect(() => {
    setoobCode(urlParams.get("oobCode"));
  }, [urlParams]);

  const changePassword = async () => {
    try {
      setIsLoading(true);
      await confirmPasswordReset(auth, oobCode, newPassword).then((res) => {
        setError("success");
        setIsLoading(false);
        setNewPassword("");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
    } catch (e) {
      const errorCode = e.code;
      switch (errorCode) {
        case "auth/invalid-action-code":
          setError("Invalid or Expired Link");
          setIsLoading(false);
          break;

        default:
          setError("error " + error);
          setIsLoading(false);
      }
    }
  };

  return (
    <section className="h-[80vh] flex items-start justify-center font-dm-sans">
      <div className="w-full px-6">
        <div className="">
          <div className="flex items-start justify-center h-full">
            <div className="max-w-[574px] w-full space-y-4">
              <h1 className="font-medium text-mobile-h2">
                Reset your password for Quizmine
              </h1>
              <hr></hr>
              <InputBase
                name="new password"
                label="New password"
                type="password"
                ariaLabel="Email"
                value={newPassword}
                disabled={isLoading}
                onChange={(value) => setNewPassword(value)}
              />

              <div className="text-left">
                {error.length > 0 ? (
                  error === "success" ? (
                    <div>
                      <span className="text-lime-700">
                        Password changed Successfully
                      </span>
                    </div>
                  ) : (
                    <span className="text-red-600">{error}</span>
                  )
                ) : (
                  ""
                )}
              </div>
              <button
                disabled={isLoading}
                onClick={changePassword}
                className="flex items-center justify-center w-full px-6 py-4 space-x-2 rounded-lg bg-slate-blue filter hover:brightness-125"
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  <>
                    <span className="text-white">Confirm new password</span>
                    <ArrowRightIcon className="w-5 text-white" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
