import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/home";
import NavBar from "./components/NavBar";
import ResetPassword from "./pages/reset-password";
import ForgotPassword from "./pages/forgot-password";

function App() {
  return (
    <div className="min-h-screen text-center">
      <div className="min-h-screen">
        <div className="px-6">
          <NavBar />
        </div>
        <section className="h-full px-6 m-auto md:px-0 max-w-default">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="auth/reset-password" element={<ResetPassword />} />
          </Routes>
        </section>
      </div>
    </div>
  );
}

export default App;
