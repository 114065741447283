import React, { useState } from "react";
import InputBase from "../components/InputBase";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { CircularProgress } from "@mui/material";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendResetEmail = () => {
    try {
      if (email.length > 0) {
        setIsError("");
        setIsLoading(true);
        sendPasswordResetEmail(auth, email).then(() => {
          setIsLoading(false);
          setIsError("success");
        });
      }
    } catch (e) {
      console.log(e);
      switch (e) {
        default:
          setIsError(`Error : ${e}`);
          setIsLoading(false);
          break;
      }
    }
  };
  return (
    <section className="h-[80vh] flex items-start justify-center font-dm-sans">
      <div className="w-full px-6">
        <div className="">
          <div className="flex items-start justify-center h-full">
            <div className="max-w-[574px] w-full space-y-4">
              <h1 className="font-medium text-mobile-h2">
                Forgot your Quizmine password?
              </h1>
              <hr></hr>
              <InputBase
                name="Email"
                label="Enter your email"
                type="email"
                ariaLabel="Email"
                value={email}
                disabled={isLoading}
                onChange={(value) => setEmail(value)}
              />

              <div className="text-left">
                {isError.length > 0 ? (
                  isError === "success" ? (
                    <div className="text-center">
                      <span className="text-lime-700 text-center">
                        Check your email
                      </span>
                    </div>
                  ) : (
                    <span className="text-red-600">{isError}</span>
                  )
                ) : (
                  ""
                )}
              </div>

              {isError === "success" ? (
                <></>
              ) : (
                <button
                  disabled={isLoading}
                  onClick={sendResetEmail}
                  className="flex items-center justify-center w-full px-6 py-4 space-x-2 rounded-lg bg-slate-blue filter hover:brightness-125"
                >
                  {isLoading ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    <>
                      <span className="text-white">Send Email</span>
                      <ArrowRightIcon className="w-5 text-white" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
