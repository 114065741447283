import React from "react";

const HomePage = () => {
  return (
    <div className="mt-[50px] md:mt-20 text-center m-auto">
      <div className="max-w-[790px] m-auto">
        <h1 className="font-medium text-slate-headline text-mobile-h1 md:text-desktop-h1">
          Mock Generation Simplified
        </h1>
        <p className="mt-[30px] text-desktop-subheading">
          Web platform for generating quizzes, paying for the generated quiz,
          downloading PDFs with the marking scheme or taking the test online.
        </p>
      </div>
      <div className="flex  justify-between gap-4 mt-8 md:gap-1 md:space-x-4 md:flex-row md:mt-10"></div>
      {/* <div className="relative mt-[56px] md:mt-20 flex items-center justify-center h-[194px] md:h-[680px] bg-white bg-opacity-70 rounded-3xl">
    <img className="h-[188px] md:h-auto mt-8 md:mt-[100px]" src="/assets/heros/groupbanner.svg" alt="" />
  </div> */}
      <a href="https://www.mockgenerator.quizmine.app/">
        <button className="px-8 mx-4 h-[60px] w-full md:w-auto font-medium my-2 text-white rounded-lg bg-slate-blue text-desktop-paragraph filter hover:brightness-125">
          Create a Mock Exam
        </button>
      </a>
      <a href="https://www.online-test.quizmine.app/">
        <button className="px-8 mx-4 h-[60px] w-full md:w-auto font-medium my-2 text-white rounded-lg bg-slate-blue text-desktop-paragraph filter hover:brightness-125">
          Take Or Create Online Test
        </button>
      </a>
    </div>
  );
};

export default HomePage;
