import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav>
      <div className="m-auto max-w-default">
        <div className="flex items-center justify-between py-8">
          <div className="flex items-center">
            <Link to="/">
              <img
                className="w-[160px] md:[100px] h-auto"
                src="/assets/logo.png"
                alt="Quizmine logo"
              />
            </Link>
          </div>
          <div>
            <div className="hidden md:block">
              <ul className="hidden space-x-12 font-medium md:flex text-slate-headline"></ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
