import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMuk_qgLSp6Qt2E9n-xf0x8zIzb_X5Pik",
  authDomain: "projects-mvp.firebaseapp.com",
  projectId: "projects-mvp",
  storageBucket: "projects-mvp.appspot.com",
  messagingSenderId: "931095654311",
  appId: "1:931095654311:web:d76223a1bc71ef93225082",
  measurementId: "G-G2MV7Q0QEL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export { auth, app };
